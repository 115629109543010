<template>
  <b-overlay
    :show="loading"
    rounded="sm"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
  >
    <b-card class="product-edit-wrapper">
      <div class="d-flex mb-2">
        <div class="d-flex ml-auto">
          <h3 class="mr-2">Customize</h3>
          <b-form-checkbox
            :checked="is_customize"
            class="custom-control-primary"
            name="check-button"
            switch
            @change="is_customize = !is_customize"
          />
        </div>
      </div>

      <b-row v-if="!is_customize">
        <!-- Event -->
        <b-col cols="3">
          <b-card title="Event" class="mb-0">
            <!-- Search -->
            <b-col cols="12">
              <div class="">
                <b-form-input
                  v-model="searchQueryEvent"
                  placeholder="Search..."
                />
              </div>
            </b-col>
            <div>
              <b-form-checkbox
                v-for="(item, index) in eventData"
                :key="item.id"
                v-model="eventData[index].checked"
                class="mt-2"
                :checked="eventData[index].checked"
                @change="onChangeCheckBox(item, 'eventData', true)"
              >
                {{ item.title }}
              </b-form-checkbox>
            </div>
            <div class="mx-2 mb-2 mt-2">
              <b-row>
                <!-- Pagination -->
                <b-col
                  cols="12"
                  sm="12"
                  class="d-flex align-items-center justify-content-center"
                >
                  <b-pagination
                    v-model="currentPageEvent"
                    :total-rows="totalEvents"
                    :per-page="perPageEvent"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
        <!-- Asset -->
        <b-col cols="3">
          <b-card title="Asset" class="mb-0">
            <!-- Search -->
            <b-col cols="12">
              <div class="">
                <b-form-input
                  v-model="searchQueryAsset"
                  placeholder="Search..."
                />
              </div>
            </b-col>
            <div>
              <b-form-checkbox
                v-for="(item, index) in assetData"
                :key="item.id"
                v-model="assetData[index].checked"
                class="mt-2"
                :checked="assetData[index].checked"
                @change="onChangeCheckBox(item, 'assetData', true)"
              >
                {{ item.title }}
              </b-form-checkbox>
            </div>
            <div class="mx-2 mb-2 mt-2">
              <b-row>
                <!-- Pagination -->
                <b-col
                  cols="12"
                  sm="12"
                  class="d-flex align-items-center justify-content-center"
                >
                  <b-pagination
                    v-model="currentPageAsset"
                    :total-rows="totalAssets"
                    :per-page="perPageAsset"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
        <!-- Elearning -->
        <b-col cols="3">
          <b-card title="E-learning" class="mb-0">
            <!-- Search -->
            <b-col cols="12">
              <div class="">
                <b-form-input
                  v-model="searchQueryElearn"
                  placeholder="Search..."
                />
              </div>
            </b-col>
            <div>
              <b-form-checkbox
                v-for="(item, index) in elearnData"
                :key="item.id"
                v-model="elearnData[index].checked"
                class="mt-2"
                :checked="elearnData[index].checked"
                @change="onChangeCheckBox(item, 'elearnData', true)"
              >
                {{ item.title }}
              </b-form-checkbox>
            </div>
            <div class="mx-2 mb-2 mt-2">
              <b-row>
                <!-- Pagination -->
                <b-col
                  cols="12"
                  sm="12"
                  class="d-flex align-items-center justify-content-center"
                >
                  <b-pagination
                    v-model="currentPageElearn"
                    :total-rows="totalElearns"
                    :per-page="perPageElearn"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
        <b-col cols="3">
          <b-card title="Publication" class="mb-0">
            <!-- Search -->
            <b-col cols="12">
              <div class="">
                <b-form-input
                  v-model="searchQueryPublication"
                  placeholder="Search..."
                />
              </div>
            </b-col>
            <div>
              <b-form-checkbox
                v-for="(item, index) in publicationData"
                :key="item.id"
                v-model="publicationData[index].checked"
                class="mt-2"
                :checked="publicationData[index].checked"
                @change="onChangeCheckBox(item, 'publicationData', true)"
              >
                {{ item.title }}
              </b-form-checkbox>
            </div>
            <div class="mx-2 mb-2 mt-2">
              <b-row>
                <!-- Pagination -->
                <b-col
                  cols="12"
                  sm="12"
                  class="d-flex align-items-center justify-content-center"
                >
                  <b-pagination
                    v-model="currentPagePublication"
                    :total-rows="totalPublications"
                    :per-page="perPagePublication"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
      </b-row>

      <validation-observer ref="addProductValidation" v-if="is_customize">
        <!-- form -->
        <b-form class="mt-2">
          <b-row>
            <!-- Url -->
            <b-col md="6">
              <b-form-group
                label="Url"
                label-for="product-add-title"
                class="mb-2"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Url"
                  rules="required"
                >
                  <b-form-input
                    id="product-add-title"
                    v-model="currentSpotlight.url"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Color -->
            <b-col md="6">
              <b-form-group
                label="Color"
                label-for="product-add-title"
                class="mb-2"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Color"
                  rules="required"
                >
                  <b-form-input
                    type="color"
                    id="product-add-title"
                    v-model="currentSpotlight.color_code"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <!-- Overview images -->
              <b-form-group
                label="Overview images"
                label-for="product-add-region"
                class="mb-2"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Overview images"
                  rules="required"
                >
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="primary"
                    @click="$refs.file.click()"
                    >Select image</b-button
                  >
                  <input
                    type="file"
                    ref="file"
                    style="display: none"
                    accept="image/*"
                    @change="uploadImage"
                  />
                  <div class="border rounded p-2 pb-0 mt-2">
                    <h6 v-if="!currentSpotlight.image.url">
                      No photos added yet
                    </h6>
                    <b-img
                      v-else
                      v-model="currentSpotlight.image.url"
                      center
                      fluid
                      :src="currentSpotlight.image.url"
                      height="100"
                    />
                  </div>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Transalate -->
            <b-col cols="12">
              <label for="description-form">Options for translate each region</label>
              <transition-group
                id="description-form"
                name="custom-classes"
                enter-active-class="animate__animated animate__fadeInDown"
                leave-active-class="animate__animated animate__fadeOutUp"
                :duration="0"
              >
                <div v-for="index in countDescription" :key="index">
                  <!-- repeater from -->
                  <b-col cols="12" class="mb-2">
                    <div class="border rounded p-2">
                      <div
                        v-if="arrayDescription.length > 1 && index !== 1"
                        class="d-flex flex-row-reverse py-50 px-25 col-sm-12"
                      >
                        <feather-icon
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeItemDescription(index - 1)"
                        />
                      </div>
                      <b-row>
                        <!-- Language -->
                        <b-col md="6">
                          <b-form-group
                            label="Language"
                            label-for="product-add-region"
                            class="mb-2"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Language"
                              rules="required"
                            >
                              <v-select
                                id="region-edit-region"
                                v-model="arrayDescription[index - 1].region_id"
                                :dir="
                                  $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                "
                                :options="languageOptions"
                                :clearable="false"
                                :disabled="index === 1"
                                :selectable="
                                  (option) => {
                                    return !arrayDescription.find(
                                      (x) => x.region_id.value === option.value
                                    );
                                  }
                                "
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Title -->
                        <b-col md="6">
                          <b-form-group
                            label="Title"
                            label-for="product-add-title"
                            class="mb-2"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Title"
                              rules="required"
                            >
                              <b-form-input
                                id="product-add-title"
                                v-model="arrayDescription[index - 1].title"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Offering type -->
                        <b-col md="6">
                          <b-form-group
                            label="Offering type"
                            label-for="product-add-title"
                            class="mb-2"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Offering type"
                              rules="required"
                            >
                              <b-form-input
                                id="product-add-title"
                                v-model="
                                  arrayDescription[index - 1].offeringType
                                "
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Description -->
                        <b-col md="6">
                          <b-form-group
                            label="Description"
                            label-for="product-add-Summary"
                            class="mb-2"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Description"
                              rules="required"
                            >
                              <b-form-textarea
                                id="product-add-title"
                                v-model="
                                  arrayDescription[index - 1].shortDescription
                                "
                                rows="6"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </div>
              </transition-group>
              <b-col cols="12" class="mt-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="primary"
                  @click="addNewItemInItemFormDescription"
                >
                  Add description form
                </b-button>
              </b-col>
            </b-col>
            <b-col cols="12" class="d-flex">
              <b-button
                :disabled="spotlightData.length === 4"
                @click="appendSpotlight"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1 ml-auto"
                >Append asset</b-button
              >
            </b-col>
          </b-row>
        </b-form>
        <!--/ form -->
      </validation-observer>

      <b-col :key="componentKey" cols="12" class="mt-2">
        <b-row>
          <b-col cols="12">
            <b-form-group label="Spotlight" label-for="product-benefits">
              <swiper
                v-if="!spotlightData.length"
                style="
                  border: solid 1px;
                  border-color: #b8c2cc;
                  border-radius: 1rem;
                  padding: 1rem;
                "
                class="swiper-multiple"
                :options="swiperOptionsBenefits"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              >
                <swiper-slide
                  v-for="(data, indexRelated) in 1"
                  :key="indexRelated + 'related'"
                >
                  <b-row style="justify-content: center">
                    <b-col
                      cols="6"
                      style="
                        border: solid 2px;
                        border-radius: 1rem;
                        border-color: #db536a;
                      "
                    >
                      <div class="related-source" style="height: 12rem">
                        <span v-if="!data.image">No offering added yet</span>
                      </div>
                    </b-col>
                  </b-row>
                </swiper-slide>
                <div slot="button-next" class="swiper-button-next" />
                <div slot="button-prev" class="swiper-button-prev" />
              </swiper>
              <swiper
                v-else
                ref="relatedSource"
                style="
                  border: solid 1px;
                  border-color: #b8c2cc;
                  border-radius: 1rem;
                  padding: 1rem;
                "
                class="swiper-multiple"
                :options="swiperOptionsBenefits"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              >
                <swiper-slide
                  v-for="(data, indexRelated) in spotlightData"
                  :key="indexRelated + 'related'"
                >
                  <b-row style="justify-content: center">
                    <b-col
                      v-if="!data.isCustomize"
                      cols="8"
                      style="
                        border: solid 2px;
                        border-top-left-radius: 1rem;
                        border-bottom-left-radius: 1rem;
                        border-color: #db536a;
                      "
                    >
                      <div class="d-flex">
                        <div class="font-weight-bold my-1" v-if="data.product">
                          {{
                            `[${mapOferringType(
                              data
                            )}] - ${formatTitle(
                              data,
                              mapOferringType(data)
                            )}`
                          }}
                        </div>
                        <b-badge
                          pill
                          :variant="`light-success`"
                          class="text-capitalize ml-auto my-1 h-100"
                        >
                          Offering
                        </b-badge>
                        <!-- <v-select
                          :value="spotlightData[indexRelated].order"
                          class="ml-1"
                          :options="ordinalNumberOptions"
                          style="margin-top: 3px"
                          :clearable="false"
                          @change="
                            handleSelectChange(
                              spotlightData[indexRelated].order,
                              $event
                            )
                          "
                        /> -->
                        <select
                          :value="spotlightData[indexRelated].order"
                          class="ml-1 my-1 border h-100"
                          @change="
                            handleSelectChange(
                              spotlightData[indexRelated].order,
                              $event,
                              indexRelated
                            )
                          "
                        >
                          <option
                            v-for="item in ordinalNumberOptions"
                            :key="item"
                            :value="item"
                          >
                            {{ item }}
                          </option>
                        </select>
                      </div>
                      <div
                        class="related-source"
                        style="height: 15rem; margin-bottom: 1rem"
                      >
                        <b-img
                          v-if="data.product && data.product.images.length > 0"
                          fluid
                          :src="
                            data.product.images.find(
                              (x) => x.type === 'header_image'
                            )
                              ? data.product.images.find(
                                  (x) => x.type === 'header_image'
                                ).link
                              : ''
                          "
                          style="height: 15rem"
                        />
                      </div>
                    </b-col>
                    <b-col
                      v-if="data.isCustomize"
                      cols="7"
                      style="
                        border: solid 2px;
                        border-top-left-radius: 1rem;
                        border-bottom-left-radius: 1rem;
                        border-color: #db536a;
                      "
                    >
                      <div class="d-flex">
                        <div class="font-weight-bold my-1">
                          {{ formatTitleCustomize(data) }}
                        </div>
                        <b-badge
                          pill
                          :variant="`light-info`"
                          class="text-capitalize ml-auto my-1 h-100"
                        >
                          Customize
                        </b-badge>
                        <select
                          :value="spotlightData[indexRelated].order"
                          class="ml-1 my-1 border h-100"
                          @change="
                            handleSelectChange(
                              spotlightData[indexRelated].order,
                              $event,
                              indexRelated
                            )
                          "
                        >
                          <option
                            v-for="item in ordinalNumberOptions"
                            :key="item"
                            :value="item"
                          >
                            {{ item }}
                          </option>
                        </select>
                      </div>
                      <div
                        class="related-source"
                        style="height: 15rem; margin-bottom: 1rem"
                      >
                        <b-img
                          v-if="data.image"
                          fluid
                          :src="data.image.url"
                          style="height: 15rem"
                        />
                      </div>
                    </b-col>
                    <b-col
                      cols="1"
                      class="back-sub-element"
                      @click="
                        removeElementRelated(
                          indexRelated,
                          'relatedSource',
                          data
                        )
                      "
                    >
                      <div class="sub-element">-</div>
                    </b-col>
                  </b-row>
                </swiper-slide>
                <div
                  slot="button-next"
                  class="swiper-button-next"
                  @click="nextSlideRelated('relatedSource')"
                />
                <div
                  slot="button-prev"
                  class="swiper-button-prev"
                  @click="previousSlideRelated('relatedSource')"
                />
              </swiper>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <div class="d-flex mt-2">
        <div class="ml-auto">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="submit"
          >
            Confirm
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="ml-2"
            :to="{ name: 'spotlight-manager' }"
          >
            Cancel
          </b-button>
        </div>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BImg,
  BCard,
  BOverlay,
  BRow,
  BCol,
  BTable,
  BPagination,
  BFormInput,
  BButton,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BCardBody,
  BFormTextarea,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";

import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required } from "@validations";
// eslint-disable-next-line import/no-cycle
import store from "@/store";
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import editProduct from "./detailProduct";
import useEventList from "../../../modal/selectRelatedAsset/eventList";
import useAssetList from "../../../modal/selectRelatedAsset/assetList";
import useElearnList from "../../../modal/selectRelatedAsset/elearnList";
import usePublication from "../../../modal/selectRelatedAsset/publicationList";
import { checkImageExtensionFile } from "@/utils/utils";
import { ref } from "@vue/composition-api";

import { mapGetters } from "vuex";

export default {
  components: {
    BCard,
    BOverlay,
    BPagination,
    BTable,
    BCol,
    BRow,
    BFormInput,
    BCard,
    BImg,
    BButton,
    BFormCheckbox,
    BFormGroup,
    Swiper,
    SwiperSlide,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BCardBody,
    BFormTextarea,
    vSelect,
    BBadge,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      stopClick: false,
      componentKey: 0,
      active: "Active",
      inactive: "Inactive",
      snowOption: {
        placeholder: "",
        theme: "snow",
      },
      swiperOptions: {
        allowTouchMove: false,
        centeredSlides: true,
        slidesPerView: 4,
        spaceBetween: 30,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperOptionsBenefits: {
        allowTouchMove: false,
        centeredSlides: true,
        slidesPerView: 2,
        spaceBetween: 40,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      readOnlyOption: {
        placeholder: "Please choose region",
        theme: "snow",
      },
      required,
      currentIndex: null,
      currentIndexRef: null,
      currentImageKey: "",
      refType: 0,
      is_customize: false,
      initSpotlight: {
        title: "",
        offeringType: "",
        url: "",
        shortDescription: "",
        image: {},
        product: {},
        isCustomize: false,
      },
      currentSpotlight: {
        title: "",
        offeringType: "",
        url: "",
        shortDescription: "",
        image: {},
        product: {},
        isCustomize: false,
      },
    };
  },
  computed: {
    ...mapGetters("app", ["offeringTypes", "regionOptions", "languageOptions"]),
  },
  watch: {
    // spotlightData: {
    //   deep: true,
    //   handler(value, oldValue) {
    //     console.log("new value", value)
    //     console.log("old value", oldValue)
    //   },
    // },
  },
  mounted() {
    this.fetchSpotlightList();
    setTimeout(() => {
      this.fetchEvents();
      this.fetchAssets();
      this.fetchPublication();
      this.fetchElearn();
    }, 1000);
  },
  setup() {
    const {
      fetchSpotlightList,
      regionId,
      loading,
      spotlightData,
      ordinalNumberOptions,
      spotlightDataCurrent,
    } = editProduct();
    const countDescription = ref(1);
    const arrayDescription = ref([
      {
        region_id: {
          label: "English",
          value: "en",
          region: "my",
        },
        title: "",
        offeringType: "",
        shortDescription: "",
      },
    ]);
    const eventData = useEventList(spotlightData);
    const assetData = useAssetList(spotlightData);
    const elearnData = useElearnList(spotlightData);
    const publicationData = usePublication(spotlightData);
    const productData = {
      event: eventData.eventData,
      asset: assetData.assetData,
      elearn: elearnData.elearnData,
      publication: publicationData.publicationData,
    };
    return {
      countDescription,
      arrayDescription,
      ordinalNumberOptions,
      regionId,
      loading,
      spotlightData,
      spotlightDataCurrent,
      fetchSpotlightList,
      ...eventData,
      ...assetData,
      ...elearnData,
      ...publicationData,
      productData,
    };
  },

  methods: {
    formatTitleCustomize(data){
      console.log("formatTitleCustomize", data)
      const found = data?.metadata?.find(x => x?.region_id?.value === "en")
      if(found){
        return `[${found?.offeringType}] - ${found?.title}` 
      }
      return `[${data?.offeringType}] - ${data?.title}` 
    },
    addNewItemInItemFormDescription() {
      this.arrayDescription.push({
        title: "",
        offeringType: "",
        shortDescription: "",
        region_id: "",
      });
      this.countDescription += 1;
    },
    removeItemDescription(index) {
      this.arrayDescription.splice(index, 1);
      this.countDescription -= 1;
    },
    async handleSelectChange(val, e, index) {
      const oldVal = val;
      const newVal = e.target.value;
      const foundIndex = this.spotlightData.findIndex(
        (x) => x.order === parseInt(newVal)
      );
      if (foundIndex != -1) {
        this.spotlightData[foundIndex].order = oldVal;
        this.spotlightData[index].order = parseInt(newVal);
        await this.spotlightData.sort((a, b) => a.order - b.order);
        await this.$refs?.relatedSource?.$swiper.slideTo(parseInt(newVal) - 1, 1);
        console.log(this.spotlightData)
      }
    },
    formatTitle(data, type) {
      var arrString = data?.product?.title?.split("-");
      if (arrString?.length > 0) {
        if (arrString[0].trim() === `[${type}]`) {
          arrString.shift();
          return arrString.join(" ");
        }
      }
      return data?.product?.title;
    },
    mapOferringType(data) {
      return this.offeringTypes?.find((x) => x.value === data?.product?.offering_type)?.label || "";
    },
    uploadImage(e) {
      const { files } = e.target;
      if (files && files.length && files[0]) {
        if (!checkImageExtensionFile(files[0].name)) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Please select a valid image (jpg, jpeg, png, gif)!",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          return;
        }
        const blob = URL.createObjectURL(files[0]);
        const reader = new FileReader();
        reader.onload = (event) => {
          this.currentSpotlight.image = {
            file: files[0],
            url: blob,
          };
          e.target.value = null;
        };
        reader.readAsArrayBuffer(files[0]);
      }
    },
    async appendSpotlight() {
      this.$refs.addProductValidation.validate().then(async (success) => {
        if (success) {
          await this.spotlightData.push({
            ...this.currentSpotlight,
            isCustomize: true,
            order: this.spotlightData.length + 1,
            metadata: this.arrayDescription,
          });
          await this.ordinalNumberOptions.push(this.spotlightData.length);
          await this.spotlightData.sort((a, b) => a.order - b.order);
          await this.$refs?.relatedSource?.$swiper.slideTo(
            this.spotlightData.length,
            1
          );
          this.currentSpotlight = await { ...this.initSpotlight };
          this.countDescription = 1
          this.arrayDescription = [
            {
              region_id: {
                label: "English",
                value: "en",
                region: "my",
              },
              name: "",
              description: "",
            },
          ]
        }
      });
    },
    async onChangeCheckBox(data, refTable) {
      const isUncheck = this.spotlightData.some(
        (e) => e?.product?.id === data.id
      );

      if (isUncheck) {
        this.spotlightData = this.spotlightData.filter(
          (item) => item?.product?.id !== data.id
        );

        let count = 0;
        this.ordinalNumberOptions = [];

        this.spotlightData = this.spotlightData.map((item) => {
          this.ordinalNumberOptions.push(++count);
          return {
            ...item,
            order: count,
          };
        });
      } else {
        if (this.spotlightData.length === 4) {
          const idx = this[refTable].findIndex((item) => item === data);
          await this.$nextTick();
          this[refTable][idx].checked = false;
          return;
        }
        await this.spotlightData.push({
          ...this.initSpotlight,
          product: data,
          order: this.spotlightData.length + 1,
        });
        await this.ordinalNumberOptions.push(this.spotlightData.length);
        await this.spotlightData.sort((a, b) => a.order - b.order);
        await this.$refs?.relatedSource?.$swiper.slideTo(
          this.spotlightData.length,
          1
        );
      }
    },
    async removeElementRelated(indexService, refIndex, data) {
      this.handleUnCheck(data)
      // await this.uncheck(data);
      // this.forceUpdate()
      await this.$nextTick(async () => {
        if (indexService === 0) {
          await this.spotlightData.shift();
        } else {
          await this.spotlightData.splice(indexService, 1);
        }
        this.ordinalNumberOptions = [];
        this.spotlightData.forEach((item, index) => {
          this.ordinalNumberOptions.push(index + 1);
          this.spotlightData[index].order = index + 1;
        });
        await this.$refs?.relatedSource?.$swiper.slideTo(indexService - 1, 1);
      });
    },
    async uncheck(data) {
      let indxData = -1;
      if (data) {
        switch (data.title.split(" ")[0]) {
          case "[Event]":
            indxData = await this.eventData.findIndex((x) => x.id === data.id);
            if (indxData !== -1) {
              this.eventData[indxData].checked = await false;
            }
            break;
          case "[Asset]":
            indxData = await this.assetData.findIndex((x) => x.id === data.id);
            if (indxData !== -1) {
              this.assetData[indxData].checked = await false;
            }
            break;
          case "[Elearn]":
            indxData = await this.elearnData.findIndex((x) => x.id === data.id);
            if (indxData !== -1) {
              this.elearnData[indxData].checked = await false;
            }
            break;
          case "[Publication]":
            indxData = await this.publicationData.findIndex(
              (x) => x.id === data.id
            );
            if (indxData !== -1) {
              this.publicationData[indxData].checked = await false;
            }
            break;
          default:
            indxData = await this.assetData.findIndex((x) => x.id === data.id);
            if (indxData !== -1) {
              this.assetData[indxData].checked = await false;
            }
            break;
        }
      }
    },
    handleUnCheck(data) {
      if (!data.isCustomize) {
        const { offering_type, id } = data.product;
        const dataList = this.productData[offering_type].value;
        const indxData = dataList.findIndex((item) => item.id === id);
        if (indxData !== -1) dataList[indxData].checked = false;
      }
    },
    async nextSlideRelated(refIndex) {
      await this.$refs[refIndex].$swiper.slideNext();
    },
    async previousSlideRelated(refIndex) {
      await this.$refs[refIndex].$swiper.slidePrev();
    },
    forceUpdate() {
      this.componentKey += 1;
    },
    // async onChangeCheckBox(data, stop = false) {
    //   if (this.stopClick) {
    //     this.stopClick = false
    //     return
    //   }
    //   if (stop) this.stopClick = true
    //   const refTable = data.offering_type === 'asset' ? 'assetData' : data.offering_type === 'event' ? 'eventData' : data.offering_type === 'elearn' ? 'elearnData' : 'publicationData'
    //   console.log(refTable)
    //   const idx = this[refTable].findIndex(item => item.id === data.id)
    //   if (this.spotlightData.find(e => e.id === data.id)) {
    //     this.spotlightData.splice(this.spotlightData.findIndex(e => e.id === data.id), 1)
    //     await this.$nextTick()
    //     this[refTable][idx].checked = false
    //   } else if (this.spotlightData.length !== 4) {
    //     await this.spotlightData.push(data)
    //     await this.$refs?.relatedSource?.$swiper.slideTo(this.spotlightData.length, 1)
    //   } else {
    //     await this.$nextTick()
    //     this[refTable][idx].checked = false
    //   }
    // },
    async uploadFiles(file) {
      const form = new FormData();
      form.append("file", file);
      const res = await store.dispatch("spotlight/updloadMedia", form);
      return res.body.id;
    },
    async submit() {
      this.loading = true;
      const product_ids = [];
      const customizeProduct = [];
      const imagesId = await Promise.all(
        this.spotlightData.map(async (item, index) => ({
          index: index,
          id: item?.image?.file
            ? await this.uploadFiles(item?.image?.file)
            : null,
        }))
      );
      this.spotlightData.forEach((x, idx) => {
        if (x.isCustomize === true) {
          customizeProduct.push({
            title: x.title,
            short_description: x.shortDescription,
            offering_type: x.offeringType,
            url: x.url,
            spotlight_media_id: x?.image?.file
              ? imagesId.find((i) => i.index === idx).id
              : x.image.id,
            color_code: x.color_code,
            ordinal_number: x.order,
            metadata: x.metadata
          });
        } else {
          product_ids.push({
            product_id: x.product.id,
            ordinal_number: x.order,
          });
        }
      });
      const productUpdateData = {
        product_ids: product_ids,
        customize_spotlights: customizeProduct,
        region_id: this.$route.params.id,
      };
      store
        .dispatch("spotlight/updateSpotlight", { spotlight: productUpdateData })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Update spotlight successfully",
              icon: "BellIcon",
              variant: "success",
            },
          });
          this.loading = false;
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${e.message}`,
              icon: "BellIcon",
              variant: "danger",
            },
          });
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css";

/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from {
  transform: translateY(20px);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}

.ql-container {
  min-height: inherit;
}

.select-image {
  flex-direction: column;
  display: flex;
  height: 100%;
  text-align: center;
  justify-content: center;
  border: dash grey;
}

.add-element {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  font-size: xx-large;
  background-color: #db536a;
  color: white;
  border-radius: 1rem;
  border-color: #db536a;
  cursor: pointer;
  justify-content: center;
  padding: 2rem;
}

.back-sub-element {
  justify-content: center;
  flex-direction: column;
  display: flex;
  border-top-right-radius: 1rem;
  border-end-end-radius: 1rem;
  background-color: #db536a;
}

.sub-element {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  font-size: xx-large;
  background-color: #db536a;
  color: white;
  border-radius: 1rem;
  border-color: #db536a;
  cursor: pointer;
  justify-content: center;
}

.select-image-benefit {
  width: 10rem;
  height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border: dashed 2px;
  border-radius: 1rem;
  border-color: #db536a;
  cursor: pointer;
}

.swiper-button-next {
  color: #db536a;
}

.swiper-button-prev {
  color: #db536a;
}

.related-source {
  flex-direction: column;
  display: flex;
  height: 100%;
  text-align: center;
  justify-content: center;
}
</style>
