import { ref } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

export default function editProduct() {
  const regionId = ref(null)
  const loading = ref(false)
  const spotlightData = ref([])
  const spotlightDataCurrent = ref([])
  const ordinalNumberOptions = ref([])
  regionId.value = router.currentRoute.params.id
  const fetchSpotlightList = () => {
    loading.value = true
    store
      .dispatch('spotlight/fetchSpotlightList', {
        order_by: 'created_at',
        order_type: 'asc',
        limit: 10,
        page: 1,
        region_ids: regionId.value,
        order_by: "ordinal_number",

      })
      .then(response => {
        response.body.data.forEach(x => {
          if (x.is_customize) {
            spotlightData.value.push({
              id: x.id,
              title: x.title,
              offeringType: x.offering_type,
              url: x.url,
              shortDescription: x.short_description,
              image: {
                url: x?.spotlight_media?.link,
                id: x.spotlight_media.id
              },
              isCustomize: true,
              color_code: x.color_code,
              order: x.ordinal_number,
              metadata: Object.keys(x.metadata).length > 0 ? x.metadata : []
            })
          } else {
            spotlightData.value.push({
              // id: x.id,
              title: "",
              offeringType: "",
              url: "",
              shortDescription: "",
              image: {},
              isCustomize: false,
              product: x.product,
              order: x.ordinal_number
            })
          }
        })
        loading.value = false
        spotlightData.value.sort((a, b) => a.order - b.order);
        spotlightData.value.forEach((item, index) => {
          ordinalNumberOptions.value.push(index + 1)
        })
      })
      .catch(error => {
        loading.value = false
        console.log(error)
      })
  }

  return {
    fetchSpotlightList,
    regionId,
    loading,
    spotlightData,
    spotlightDataCurrent,
    ordinalNumberOptions,
  }
}
